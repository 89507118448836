import { gsap } from 'gsap';

export default class Popup {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.popupId = this.rootEl.dataset.popupId;
    this.popupEl = document.querySelector(`[data-id="${this.popupId}"]`);
    this.closeEl = this.popupEl.querySelector('.js-popup-close');
    this.isOpen = false;
    this.init();
  }

  init() {
    this.bindEventListeners();
    gsap.set(this.popupEl, {
      autoAlpha: 0,
      x: '100%',
    });
  }

  bindEventListeners() {
    const self = this;
    this.rootEl.addEventListener('click', () => {
      self.show();
    });

    this.closeEl.addEventListener('click', () => {
      self.close();
      self.isOpen = false;
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        if (self.isOpen) {
          self.close();
        }
      }
    });
  }

  show() {
    const self = this;
    document.body.classList.add('is-popup-open');
    const overlay = document.createElement('div');
    overlay.classList.add('popup__overlay');

    gsap.set(overlay, {
      autoAlpha: 0,
    });

    document.body.appendChild(overlay);

    gsap.to(overlay, {
      autoAlpha: 1,
      duration: 0.5,
      ease: 'power2.inOut',
    });

    gsap.to(this.popupEl, {
      autoAlpha: 1,
      x: 0,
      duration: 0.5,
      ease: 'power2.inOut',
    });

    this.isOpen = true;

    overlay.addEventListener('click', () => {
      self.close();
    });
  }

  close() {
    document.body.classList.remove('is-popup-open');
    const overlay = document.querySelector('.popup__overlay');

    gsap.to(this.popupEl, {
      autoAlpha: 0,
      x: '100%',
      duration: 0.5,
      ease: 'power2.inOut',
    });

    gsap.to(overlay, {
      autoAlpha: 0,
      duration: 0.4,
      ease: 'power2.inOut',
      onComplete: () => {
        document.body.removeChild(overlay);
      },
    });

    this.isOpen = false;
  }
}
