/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
// Plugins
import { ModalManager } from '@esign/modal';
import CookieNotification from '@esign/cookie-notification';
import AOS from 'aos';

// Pages
import Contact from './pages/Contact';
import Nieuwland from './pages/Nieuwland';
import Team from './pages/Team';
import Home from './pages/Home';
import PropertyDetail from './pages/PropertyDetail';
import ReferenceIndex from './pages/ReferenceIndex';
import ValuationIndex from './pages/ValuationIndex';

// Components
import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import sliders from './components/sliders';
import timeline from './components/timeline/timeline';
import disableImages from './components/layout/disableImages';
import checkMixBlendMode from './components/layout/checkMixBlendMode';
import scrollToTop from './components/layout/scrollToTop';
import masonryGrid from './components/layout/masonryGrid';
import skarabee from './components/skarabee';
import modalCookies from './components/modal/modalCookies';
import zipSelect from './components/forms/zipSelect';
import Popup from './components/popup/Popup';

// Vendor
import initChoices from './vendor/choicesJS';
import swiperGallery from './vendor/esign/swiperGallery';
import urlChecker from './vendor/choicesJS/urlChecker';
import videoHeader from './components/layout/videoHeader';

require('./utils/nativeConsole');
const objectFitImages = require('object-fit-images');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// eslint-disable-next-line
window.modalManager = new ModalManager();

AOS.init();
function getBrowserAndOS() {
  const { userAgent } = navigator;
  let browserName = navigator.appName;
  let os = 'Unknown OS';
  let verOffset;
  let nameOffset;

  // Detect browser name
  if (userAgent.indexOf('Opera') !== -1 || userAgent.indexOf('OPR') !== -1) {
    browserName = 'Opera';
  } else if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1) {
    browserName = 'Microsoft Internet Explorer';
  } else if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'Chrome';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'Safari';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'Firefox';
    // eslint-disable-next-line no-cond-assign
  } else if (
    (nameOffset = userAgent.lastIndexOf(' ') + 1) < (verOffset = userAgent.lastIndexOf('/'))
  ) {
    browserName = userAgent.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // Detect operating system
  if (userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
  if (userAgent.indexOf('Linux') !== -1) os = 'Linux';

  return {
    browserName,
    os,
  };
}

const { os } = getBrowserAndOS();
if (os === 'Windows') {
  [...document.querySelectorAll('a[data-aos]')].forEach((item) => item.removeAttribute('data-aos'));
}

// Layout setup
navigation();
responsiveVideos();
disableImages();
checkMixBlendMode();
objectFitImages(
  document.querySelectorAll(
    '.flex-image img, .intro-image img, .property__block img, .reference__block-image img, .slideshow__trigger-box img, .map__wrapper img, .office__image img, .image-slide img, .ofi img, .fancybox img',
  ),
);
masonryGrid();
scrollToTop();
modalCookies();
videoHeader();
sliders();

[...document.querySelectorAll('.js-popup-trigger')].forEach((triggerEl) => {
  new Popup(triggerEl);
});

window.cookieNotification = new CookieNotification({
  variants: [],
  triggerBtn: '.js-cookie-notification-trigger',
  cookies: {
    marketing: true,
  },
});

// components
timeline();

// Forms
skarabee();

// Set zip select options
zipSelect();

// Page specific classes
const pages = {
  Contact,
  Team,
  Nieuwland,
  Home,
  PropertyDetail,
  ReferenceIndex,
  ValuationIndex,
};

const currentPage = document.documentElement.getAttribute('data-page');

if (currentPage !== 'home') {
  document.querySelectorAll('.animation').forEach((animation) => animation.classList.add('hide'));
}

if (currentPage) {
  let pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
  pageClassName = pageClassName
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('');

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

if (document.querySelector('.swiper-gallery')) {
  swiperGallery();
}
window.modalManager.checkModalOnPageLoad();

// vendor & libraries
initChoices();
urlChecker();
